import first from "../ProjectImages/total/20191011_134454000_iOS.jpg";
import sec from "../ProjectImages/total/cover.jpg";
import third from "../ProjectImages/total/20210621_055952628_iOS.jpg";
import fourth from "../ProjectImages/total/20191029_095737.jpg";
import fifth from "../ProjectImages/total/20191029_095808.jpg";
import sixth from "../ProjectImages/total/20191112_125035.jpg";
import seventh from "../ProjectImages/total/20210621_055944479_iOS.jpg";
import eighth from "../ProjectImages/total/20210621_055948783_iOS.jpg";
import nineth from "../ProjectImages/total/IMG_20190626_112821.jpg";
import tenth from "../ProjectImages/total/IMG_20190626_163358.jpg";
import elleventh from "../ProjectImages/total/received_2247670595545243.jpg";
import twelve from "../ProjectImages/total/20191112_122809.jpg";
import thirteen from "../ProjectImages/total/20191112_122802.jpg";

import fourteen from "../ProjectImages/total/20191011_183755544_iOS.jpg";
import fifthteen from "../ProjectImages/total/20191019_115236287_iOS.jpg";
import sixteen from "../ProjectImages/total/20191019_115252285_iOS.jpg";

export const TotData = [
  {
    img: first,
    title: "Image",
    cols: 2,
  },
  {
    img: sec,
    title: "Image",
    cols: 2,
  },
  {
    img: third,
    title: "Image",
    cols: 2,
  },
  {
    img: fourth,
    title: "Image",
    cols: 2,
  },
  {
    img: fifth,
    title: "Image",
    cols: 2,
  },
  {
    img: sixth,
    title: "Image",
    cols: 2,
  },
  {
    img: seventh,
    title: "Image",
    cols: 2,
  },
  {
    img: eighth,
    title: "Image",
    cols: 2,
  },
  {
    img: nineth,
    title: "Image",
    cols: 2,
  },
  {
    img: tenth,
    title: "Image",
    cols: 2,
  },
  {
    img: elleventh,
    title: "Image",
    cols: 2,
  },
  {
    img: twelve,
    title: "Image",
    cols: 2,
  },
  {
    img: thirteen,
    title: "Image",
    cols: 2,
  },
  {
    img: fourteen,
    title: "Image",
    cols: 2,
  },
  {
    img: fifthteen,
    title: "Image",
    cols: 2,
  },
  {
    img: sixteen,
    title: "Image",
    cols: 2,
  },
];
