import first from "../ProjectImages/five/Bad-1-2.jpg";
import sec from "../ProjectImages/five/Bad-1-3.jpg";
import third from "../ProjectImages/five/Bad-1-4.jpg";
import fourth from "../ProjectImages/five/Bad-1-5.jpg";
import fifth from "../ProjectImages/five/Bad-1-6.jpg";
import sixth from "../ProjectImages/five/Bad-1-7.jpg";
import seventh from "../ProjectImages/five/Bad-2-1.jpg";
import eighth from "../ProjectImages/five/Bad-2-5.jpg";

import nine from "../ProjectImages/five/Bad-2-6.jpg";
import ten from "../ProjectImages/five/Ute-2.jpg";
import elleven from "../ProjectImages/five/Ute-4.jpg";
import twelve from "../ProjectImages/five/Ute-15.jpg";
import thirtheen from "../ProjectImages/five/Ute-16.jpg";
import fourtheen from "../ProjectImages/five/Ute-17.jpg";
import fiftheen from "../ProjectImages/five/Ute-19.jpg";
import sixtheen from "../ProjectImages/five/Ute-26.jpg";
import seventeen from "../ProjectImages/five/Ute-27.jpg";
export const fiveData = [
  {
    img: first,
    title: "Image",
    cols: 2,
  },
  {
    img: sec,
    title: "Image",
    cols: 2,
  },
  {
    img: third,
    title: "Image",
    cols: 2,
  },
  {
    img: fourth,
    title: "Image",
    cols: 2,
  },
  {
    img: fifth,
    title: "Image",
    cols: 2,
  },
  {
    img: sixth,
    title: "Image",
    cols: 2,
  },
  {
    img: seventh,
    title: "Image",
    cols: 2,
  },
  {
    img: eighth,
    title: "Image",
    cols: 2,
  },
  {
    img: nine,
    title: "Image",
    cols: 2,
  },
  {
    img: ten,
    title: "Image",
    cols: 2,
  },
  {
    img: elleven,
    title: "Image",
    cols: 2,
  },
  {
    img: twelve,
    title: "Image",
    cols: 2,
  },
  {
    img: thirtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: fourtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: fiftheen,
    title: "Image",
    cols: 2,
  },
  {
    img: sixtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: seventeen,
    title: "Image",
    cols: 2,
  },
];
