import first from "../ProjectImages/funky/first.jpg";
import sec from "../ProjectImages/funky/sec.jpg";
import third from "../ProjectImages/funky/Photo_6553647_DJI_47_jpg_4105091_0_202111513486_photo_original.jpg";
import fourth from "../ProjectImages/funky/Photo_6553771_DJI_171_jpg_4597049_0_2021123105214_photo_original.jpg";
import fifth from "../ProjectImages/funky/Photo_6553773_DJI_173_jpg_4037693_0_2021123105238_photo_original.jpg";
import sixth from "../ProjectImages/funky/Photo_6553774_DJI_174_jpg_4500964_0_202112310538_photo_original.jpg";
import seventh from "../ProjectImages/funky/Photo_6553778_DJI_178_jpg_4317991_0_2021123105456_photo_original.jpg";
import eighth from "../ProjectImages/funky/Photo_6553779_DJI_179_jpg_4051185_0_2021123105512_photo_original.jpg";

import nine from "../ProjectImages/funky/20210202_085443.jpg";
import ten from "../ProjectImages/funky/20210202_085606.jpg";
import elleven from "../ProjectImages/funky/20210817_090115.jpg";
import twelve from "../ProjectImages/funky/20210817_090147.jpg";
import thirtheen from "../ProjectImages/funky/20210817_090217.jpg";
import fourtheen from "../ProjectImages/funky/20210817_090237.jpg";
import fiftheen from "../ProjectImages/funky/20210817_090305.jpg";
import sixtheen from "../ProjectImages/funky/20201014_114202662_iOS(1).jpg";
export const funkyData = [
  {
    img: first,
    title: "Image",
    cols: 2,
  },
  {
    img: sec,
    title: "Image",
    cols: 2,
  },
  {
    img: third,
    title: "Image",
    cols: 2,
  },
  {
    img: fourth,
    title: "Image",
    cols: 2,
  },
  {
    img: fifth,
    title: "Image",
    cols: 2,
  },
  {
    img: sixth,
    title: "Image",
    cols: 2,
  },
  {
    img: seventh,
    title: "Image",
    cols: 2,
  },
  {
    img: eighth,
    title: "Image",
    cols: 2,
  },
  {
    img: nine,
    title: "Image",
    cols: 2,
  },
  {
    img: ten,
    title: "Image",
    cols: 2,
  },
  {
    img: elleven,
    title: "Image",
    cols: 2,
  },
  {
    img: twelve,
    title: "Image",
    cols: 2,
  },
  {
    img: thirtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: fourtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: fiftheen,
    title: "Image",
    cols: 2,
  },
  {
    img: sixtheen,
    title: "Image",
    cols: 2,
  },
];
