import first from "../ProjectImages/tjome/IMG_20200427_092936.jpg";
import sec from "../ProjectImages/tjome/IMG_20200427_092950.jpg";
import third from "../ProjectImages/tjome/IMG_20200427_093329.jpg";
import fourth from "../ProjectImages/tjome/IMG_20200427_093352.jpg";
import fifth from "../ProjectImages/tjome/IMG_20200427_093402.jpg";
import sixth from "../ProjectImages/tjome/IMG_20200427_093409.jpg";
import seven from "../ProjectImages/tjome/20210817_141739.jpg";
import eighth from "../ProjectImages/tjome/IMG_20200427_093434.jpg";
import nineth from "../ProjectImages/tjome/IMG_20200427_093445.jpg";
import tenth from "../ProjectImages/tjome/IMG_20200427_093455.jpg";
import elleventh from "../ProjectImages/tjome/IMG_20200427_093504.jpg";
import twelve from "../ProjectImages/tjome/IMG_20200519_181026.jpg";
import thirteen from "../ProjectImages/tjome/IMG_20200519_184503.jpg";

import fourtheen from "../ProjectImages/tjome/20210817_141804.jpg";
import fiftheen from "../ProjectImages/tjome/20210817_141830.jpg";
import sixtheen from "../ProjectImages/tjome/IMG_20200427_093428.jpg";

export const tjomeData = [
  {
    img: first,
    title: "Image",
    cols: 2,
  },
  {
    img: sec,
    title: "Image",
    cols: 2,
  },
  {
    img: fourtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: fiftheen,
    title: "Image",
    cols: 2,
  },
  {
    img: seven,
    title: "Image",
    cols: 2,
  },
  {
    img: third,
    title: "Image",
    cols: 2,
  },
  {
    img: fourth,
    title: "Image",
    cols: 2,
  },
  {
    img: fifth,
    title: "Image",
    cols: 2,
  },
  {
    img: sixth,
    title: "Image",
    cols: 2,
  },

  {
    img: eighth,
    title: "Image",
    cols: 2,
  },
  {
    img: nineth,
    title: "Image",
    cols: 2,
  },
  {
    img: sixtheen,
    title: "Image",
    cols: 2,
  },
  {
    img: tenth,
    title: "Image",
    cols: 2,
  },
  {
    img: elleventh,
    title: "Image",
    cols: 2,
  },
  {
    img: twelve,
    title: "Image",
    cols: 2,
  },
  {
    img: thirteen,
    title: "Image",
    cols: 2,
  },
];
