import {Grid, useMediaQuery} from "@material-ui/core";
import ImageList from "@material-ui/core/ImageList";
import { husoyData } from "../data/husoyData";
import ImageListItem from "@material-ui/core/ImageListItem";
import BackButton from "../projectComponents/backButton";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { SRLWrapper } from "simple-react-lightbox";

export function ProjectThree() {
    const classes = useStyles();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const cols = matchDownMd ? 2:4;
    return (
        <div className={classes.root}>
            <SRLWrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <h4>Funkis enebolig på Nøtterøy</h4>
                        <p style={{ padding: "24px 0px" }}>
                            Trykk på et bilde for galleri-modus
                        </p>
                        <hr style={{ color: "#422776", width: "80%" }} />
                    </Grid>
                </Grid>
                <ImageList className={classes.imageList} gap={64} cols={cols}>
                    {husoyData.map((item) => (
                        <ImageListItem
                            className={classes.imgItem}
                            key={item.img}
                            cols={item.cols}
                        >
                            <img className={classes.projectImg} src={item.img} alt={item.title} />
                        </ImageListItem>
                    ))}
                    <div>
                        <BackButton />
                    </div>
                </ImageList>
            </SRLWrapper>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        marginTop: "64px",
        [theme.breakpoints.down("sm")]: {
            display: "block",
            justifyContent: "center",
        },
    },
    imageList: {
        width: "900px",
        [theme.breakpoints.down("sm")]: {
            margin: "unset!important",
            width: "100%",
        },
    },
    imgItem: {
        [theme.breakpoints.down("sm")]: {
        },
    },
    projectImg: {
        width: "450px!important",
        height: "250px!important",
        objectFit: "cover",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%!important",
            height: "auto!important",
        },
    },
}));